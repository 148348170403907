import React, { Fragment } from 'react'
import brandcanvas from "../assests/svgs/brandcanvas.svg"
import ticketcanvas from "../assests/svgs/ticketcanvas.svg"
import ImageField from "../components/ImageField"
import Cards from '../components/Cards'
import TicketsArray from '../assests/data/TicketsArray'




const Tickets = () => {
    return (
        <>
        <div className='sm:px-0 sm:pl-16 md:20 lg:pl-24 xl:pl-28 flex flex-col gap-24 mt-12'>

       
            <div className="relative px-5 sm:px-0">

                <div className='flex flex-col gap-12 sm:gap-24  items-start '>
                    <h1 className='max-w-sm sm:max-w-full text-left text-maincolor text-6xl  lg:text-8xl font-gillRoy900 '>Get your{" "}<span className='text-black'>Early bird</span><br />Tickets<span className="text-black">{" "}Now!</span></h1>

                    <h3 className='font-gillRoy600 text-40 text-black w-full   flex-wrap max-w-sm sm:max-w-xl xl:max-w-full mt-[30vw] sm:mt-[40vw] lg:mt-[0vw]'>Get <span className='font-gillRoy700 text-maincolor'>30%</span> Discount using <span className='text-maincolor'>Promo Code</span></h3>

                    <div className='w-full p-7 sm:p-0 max-w-[250px] sm:h-[220px]  sm:max-w-[400px] rounded-30 border-4 border-solid border-maincolor flex flex-col items-center justify-evenly'>

                        <div className='border-2 border-dashed border-black rounded-2xl p-3 sm:p-5 sm:h-[70px] flex items-center'>
                            <h2 className='text-maincolor text-center uppercase sm:text-2xl sm:text-40 font-gillRoy600 leading-none tracking-widest'>Crypto 306</h2>
                        </div>
                        <span className='text-black text-lg sm:text-3xl tracking-normal font-gillRoy600 leading-normal mt-4 sm:0'>First 100 Tickets Only</span>
                    </div>
                </div>

                <ImageField taggedElem={brandcanvas} altText="error-icons" className="absolute right-12  xl:-right-96 -z-30 -top-10 xl:-top-40 opacity-25"/>
                <ImageField taggedElem={ticketcanvas} altText="error-icons" className="absolute w-1/2 sm:w-2/5 xl:w-fit  right-0 -z-20 top-44 sm:top-28" />
                

            </div>

        <div className='flex flex-col gap-12 sm:gap-20'>

        <h1 className='text-left text-maincolor text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-gillRoy900 px-5 sm:px-0'>Tickets</h1>

        <div className='flex flex-col gap-12 lg:gap-0 lg:flex-row items-center lg:items-start justify-around w-full  px-5 sm:0'>

        {
            TicketsArray.map((element, key) => (
                <Fragment key={key}>

                    <Cards cardHeadingProps={element.cardHeading} cardSubHeadingProps={element.cardSubHeading}  
                    cardSubScript = {element.cardSubScript}
                    cardBodyHeading={element.cardBodyHeading} cardBodyContent={element.cardBodyContent} cardButtonText={element.cardButtonText} cardButtonLink = {element.cardButtonLink}/>
                </Fragment>
            ))
            }
        </div>



        </div>


            </div>

        </>
    )
}

export default Tickets
