const ConstArray = [
    {
        cardHeading: "Basic 306",
        cardSubHeading: "350",
        cardSubScript: "USD",
        cardBodyHeading: "Identified as Basic Attendee",
        cardBodyContent : [
            "2-Day Access to Event",
            "1-on-1 networking opportunities with exhibitors"
        ],
        cardButtonLink: "/book-tickets",
        cardButtonText: "buy now"
    },
    {
        cardHeading: "Angel 306",
        cardSubHeading: "1500",
        cardSubScript: "USD",
        cardBodyHeading: "Identified as Basic Attendee",
        cardBodyContent : [
            "2-Day Access to Event",
            "1-on-1 networking opportunities with exhibitors",
            "1 Lunch Pass",
            "Interview with Crypto 306 Team and publishing on all social media of Crypto 306 & on 100 Media Outlets."
        ],
        cardButtonLink: "/book-tickets",
        cardButtonText: "buy now"
    }

]

export default ConstArray