import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

const Cards = ({cardHeadingProps, cardSubHeadingProps, cardSubScript,cardBodyHeading, cardBodyContent, cardButtonText, cardButtonLink}) => {
  return (
   <>
   <div className= "ticket-card w-full flex flex-col items-center  border-4 border-solid border-maincolor rounded-30 bg-white overflow-hidden">

    <div className="py-8 flex flex-col items-center gap-5 bg-maincolor w-full">
            <h2 className='text-white font-gillRoy600 text-3xl leading-normal'>{cardHeadingProps}</h2>
                  <h3 className='text-white font-gillRoy900 text-8xl leading-tight'>{cardSubHeadingProps}
                      <span className='text-2xl font-gillRoy700 -ml-1'>
                          {cardSubScript}
                      </span>
                  </h3>
    </div>

    <div className='py-8 flex flex-col gap-8 *:text-black px-5'>
        <h4 className='font-gillRoy900 text-black text-2xl'>{cardBodyHeading}</h4>


        <ul className='flex flex-col gap-5 ps-4'>
            {cardBodyContent.map((element, key) => (
                <li key={key} className='text-black font-gillRoy600 text-base sm:text-lg list-disc'>{element}</li>
            ))}
        </ul>

    </div>


    <div className='pb-8 px-5 w-full'>
        <Link to = {cardButtonLink} className='w-full bg-maincolor rounded-30 overflow-hidden py-3 hover:bg-maincolor/80'><Button className="w-full bg-transparent uppercase text-white font-gillRoy900">{cardButtonText}</Button></Link>
    </div>

   </div>
   </>
  )
}

export default Cards
